import React, { useEffect, useState } from "react";
import axiosClient from "../../../util/AxiosClient";
import { Button } from "react-bootstrap";

export default function ListWinVideo() {
  const [videos, setVideos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const videosPerPage = 5; // Number of videos per page

  useEffect(() => {
    fetchWinVideo();
  }, []);

  const fetchWinVideo = async () => {
    try {
      const response = await axiosClient.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/play-record/all-win`,
        {
          withCredentials: true,
        }
      );
      const res = response.data.data;
      setVideos(res);
    } catch (error) {
      console.log(error);
    }
  };

  const download = async (videoUrl) => {
    try {
      const response = await fetch(videoUrl, { method: "GET" });
      const blob = await response.blob();

      const fileName = decodeURIComponent(
        videoUrl.split("/").pop().split("?")[0]
      );

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Failed to download file:", error);
    }
  };

  // Calculate the videos to display for the current page
  const indexOfLastVideo = currentPage * videosPerPage;
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  const currentVideos = videos.slice(indexOfFirstVideo, indexOfLastVideo);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(videos.length / videosPerPage);

  // Generate pagination buttons with ellipsis
  const getPaginationButtons = () => {
    const buttons = [];
    const delta = 2; // Number of pages to show around the current page

    // Add the first page
    if (currentPage > delta + 2) {
      buttons.push(
        <Button
          key={1}
          className="m-1"
          variant="outline-primary"
          onClick={() => handlePageChange(1)}
        >
          1
        </Button>
      );
      buttons.push(<span key="start-ellipsis">...</span>);
    }

    // Add pages around the current page
    for (
      let i = Math.max(1, currentPage - delta);
      i <= Math.min(totalPages, currentPage + delta);
      i++
    ) {
      buttons.push(
        <Button
          key={i}
          className="m-1"
          style={{backgroundColor:currentPage===i?"gray":"#f1c40f"}}
          variant={i === currentPage ? "primary" : "outline-primary"}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Button>
      );
    }

    // Add the last page
    if (currentPage < totalPages - delta - 1) {
      buttons.push(<span key="end-ellipsis">...</span>);
      buttons.push(
        <Button
          key={totalPages}
          className="m-1"
          style={{backgroundColor:currentPage===totalPages?"gray":"#f1c40f"}}
          variant="outline-primary"
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </Button>
      );
    }

    return buttons;
  };

  return (
    <div className="crud-container">
        {videos.length > videosPerPage && (
            <div className="d-flex justify-content-center mt-3">
              <Button
                className="m-1"
                variant="outline-primary"
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </Button>
              {getPaginationButtons()}
              <Button
                className="m-1"
                variant="outline-primary"
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </Button>
            </div>
          )}
      <div className="row">
        <div className="col-12">
          <div className="table-responsive">
            <table className="table table-bordered mb-0 text-center">
              <thead>
                <tr>
                  <th>cam1</th>
                  <th>cam2</th>
                  <th>Winner Email</th>
                  <th>Win By</th>
                  <th>Won At</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                {currentVideos.length > 0 ? (
                  currentVideos.map((row) => (
                    <tr key={row._id}>
                      <td>
                        {row.videoUrl1 ? 
                            <div style={{ width: "100%" }}>
                            <video
                            className="video-rotate"
                            style={{
                                justifyContent: "center",
                                height: "20vw",
                                objectFit: "cover",
                                maxHeight: "200px",
                            }}
                            controls
                            muted
                            src={row.videoUrl1}
                            />
                        </div>
                       : "-"}
                      </td>
                      <td>
                      {row.videoUrl2 ? 
                        <div style={{ width: "100%" }}>
                        <video
                            className="video-rotate"
                            style={{
                            justifyContent: "center",
                            height: "20vw",
                            objectFit: "cover",
                            maxHeight: "200px",
                            }}
                            controls
                            muted
                            src={row.videoUrl2}
                        />
                        </div>
                    :"-"}
                      </td>
                      <td>{row.winnerEmail}</td>
                      <td>{row.prizeName}</td>
                      <td>{row?.wonAt || "-"}</td>
                      <td>
                        <Button
                          className="btn"
                          variant="outline-primary"
                          onClick={() => download(row?.videoUrl1)}
                        >
                          Download cam1
                        </Button>
                        <Button
                          className="btn"
                          variant="outline-primary"
                          onClick={() => download(row?.videoUrl2)}
                        >
                          Download cam2
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No videos found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import "./monitorStream.css";
import Listenner from "../components/listenner";
import axiosClient from "../util/AxiosClient";

const MonitorStream = () => {
  const [prizeDatas, setPrizeDatas] = useState([]);

  const getPrizes = async () => {
    try {
      const response = await axiosClient.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/machine/all`,
        {
          withCredentials: true,
        }
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  };

    // ใช้ useEffect เพื่อเรียกข้อมูลและตั้งเวลารีเฟรช
    useEffect(() => {
      const fetchData = async () => {
        const data = await getPrizes();
        if (data) {
          setPrizeDatas(data);
        }
      };
  
      // เรียกข้อมูลเมื่อคอมโพเนนต์เริ่มต้น
      fetchData();
  
      // ตั้ง interval เพื่อรีเฟรชข้อมูลทุก 1 นาที
      const interval = setInterval(()=>{window.location.reload()}, 15*60*1000); // 60000 มิลลิวินาที = 1 นาที
  
      // ล้าง interval เมื่อคอมโพเนนต์ถูกถอดออก
      return () => clearInterval(interval);
    }, []);

  return (
    prizeDatas.length > 0 ? (
      <div className="play-page">
        <div className="monitorPage-container">
          {prizeDatas.map((prizeData) => (
            <div className="container-monitor-video" key={prizeData._id}>
              <div className="container-video-play">
                <p style={{ color: "black" }}>{prizeData.machineName} กล้องที่ 1</p>
                <div className="video-container">
                  <Listenner machineID={prizeData._id} camNumber={0} />
                </div>
              </div>
              <div className="container-video-play" style={{ padding: "5px" }}>
                <p style={{ color: "black" }}>{prizeData.machineName} กล้องที่ 2</p>
                <Listenner machineID={prizeData._id} camNumber={1} />
              </div>
            </div>
          ))}
        </div>
      </div>
    ) : null
  );
};

export default MonitorStream;

import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import axiosClient from "../../../util/AxiosClient";

export default function StockSummary() {
  const [stockSummary, setStockSummary] = useState([]);
  const [inStocks, setInStocks] = useState({});
  const [isLoading, setIsLoading] = useState(false)

  const handleInStockChange = (e, id) => {
    setInStocks({
      ...inStocks,
      [id]: e.target.value,
    });
  };

  const updateStock = async (id) => {
    if (isLoading) {
      return
    }
    
    const isConfirm = await Swal.fire({
      title: `Are you sure to send update stock?`,
      text: `อัปเดท ${stockSummary.find(s=>s.id === id)?.name} stock เป็น: ${inStocks[id]}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }
    setIsLoading(true)
    try {
      await axiosClient.put(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/prize/${id}`,
        {
          stockAmount: inStocks[id],
        }
      );
      Swal.fire({
        icon: "success",
        text: "stock updated successfully",
      });
      fecthStock();
    } catch (error) {
      Swal.fire({
        text: "Failed to update stock",
        icon: "error",
      });
    } finally {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    fecthStock();
  }, []);

  const fecthStock = async () => {
    try {
      const response = await axiosClient.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/dashboard/stock`,
        {
          withCredentials: true,
        }
      );
      setStockSummary(response?.data?.data?.sort((a, b) => b.hasOwner - a.hasOwner) ?? []);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };


  return (
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-bordered mb-0 text-center">
                <thead>
                  <tr>
                    <th>image</th>
                    <th>Name</th>
                    <th>inStock</th>
                    <th>hasOwner(in user + pending shipping)</th>
                    <th>เหลือจริง</th>
                  </tr>
                </thead>
                <tbody>
                  {stockSummary.length > 0 &&
                    stockSummary.map(row => (
                      <tr key={row.id} style={row.inStock - row.hasOwner <= 0 ? {color:'red'} : (row.inStock - row.hasOwner)/(row.inStock) <= 0.3 ? {color:'orange'} : {color:'green'}}>
                        <td>
                          <img width="100px" src={row.image} />
                        </td>
                        <td>{row.name}</td>
                        <td>
                          <Form onSubmit={(e) => {
                            e.preventDefault();
                            updateStock(row.id);
                          }}>
                            <div>
                              <div>
                                {row.inStock}
                                <br/>
                                <Form.Label>อัพเดทสินค้า</Form.Label>
                                <Form.Control
                                  type="number"
                                  value={inStocks[row.id] ?? row.inStock}
                                  onChange={(e) => handleInStockChange(e, row.id)}
                                />
                              </div>
                              <Button type="submit" variant="primary" className="mt-2">
                                อัพเดท
                              </Button>
                            </div>
                          </Form>
                        </td>
                        <td>{row.hasOwner}</td>
                        <td>{row.inStock - row.hasOwner}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

  );
}

import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import axiosClient from "../../../util/AxiosClient";

export default function CreateCoupon() {
  const navigate = useNavigate();

  const [newCoupon, setNewCoupon] = useState({});

  const createCoupon = async (e) => {
    e.preventDefault();

    await axiosClient
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/coupon/`, newCoupon)
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.success,
        });
        navigate("/admin/coupon");
      })
      .catch(({ response }) => {
        Swal.fire({
          text: response.data.message,
          icon: "error",
        });
      });
  };

  return (
      <div className="col-12 col-sm-12 col-md-6">
        <div className="my-card">
          <div className="card-body">
            <h4 className="create-title">Create Coupon</h4>
            <hr />
            <div className="form-wrapper">
              <Form onSubmit={createCoupon}>
                <Row>
                  <Col>
                    <Form.Group controlId="machineName">
                      <Form.Label>Name</Form.Label>
                      <br/>
                      <Form.Control
                        type="text"
                        value={newCoupon.name}
                        onChange={(event) => {
                          setNewCoupon({...newCoupon, name:event.target.value});
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="machineName">
                      <Form.Label>Code</Form.Label>
                      <br/>
                      <Form.Control
                        type="text"
                        value={newCoupon.code}
                        onChange={(event) => {
                          setNewCoupon({...newCoupon, code:event.target.value});
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="machineName">
                      <Form.Label>limit</Form.Label>
                      <br/>
                      <Form.Control
                        type="number"
                        value={newCoupon.limitNumberOfUser}
                        onChange={(event) => {
                          setNewCoupon({...newCoupon, limitNumberOfUser:event.target.value});
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="machineName">
                      <Form.Label>price</Form.Label>
                      <br/>
                      <Form.Control
                        type="number"
                        value={newCoupon.price}
                        onChange={(event) => {
                          setNewCoupon({...newCoupon, price:event.target.value});
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="machineName">
                      <Form.Label>expireAt</Form.Label>
                      <br/>
                      <Form.Control
                        type="date"
                        value={newCoupon.expiredAt}
                        onChange={(event) => {
                          setNewCoupon({...newCoupon, expiredAt:event.target.value});
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  variant="primary"
                  className="mt-2"
                  size="lg"
                  block="block"
                  type="submit"
                >
                  Save
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
  );
}

import React, { useState, useEffect } from "react";
import "./vendorPage.css";
import { useNavigate } from "react-router-dom";
import ClawMachineDefault from "../../resorce/claw_machine_default.jpg"
import ClaimPrizeSummary from "../../components/crudTable/claimPrizeSummary/claimPrizeSummary";
import StockSummary from "../../components/crudTable/stockSummary/stockSummary";
import axiosClient from "../../util/AxiosClient";

const VendorPage = () => {
  const navigate = useNavigate();
  const [machines, setMachines] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("token");
  useEffect(() => {
    setIsLoading(true)
    getmachines().then((res) => {
      if (res) {
        setMachines(res?.data);
      }
      setIsLoading(false)
    });
  }, []);

  const getmachines = async () => {
    try {
      const response = await axiosClient.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/machine/vendor`,

        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      // Handle login failure
    }
  };

  const handleClickMachine = (machineID) => {
    if (!token) {
      navigate("/login");
    } else {
      navigate(`machineInfo/${machineID}`);
    }
  };

  return (
    <div className="vendor-machine-page">
      {!isLoading ? (
          <div className="container-machine">
            <h4 className="page-title">Machines</h4>
            <div className="card-container">
              {machines
            .sort((a, b) => {
              if (a.price !== b.price) {
                return a.price - b.price;
              } else {
                // If prices are equal, compare by machineName
                return a.machineName.localeCompare(b.machineName);
              }
            })
            .map((machine) =>
                <div
                  className="card-machine"
                  key={machine._id}
                  onClick={() => handleClickMachine(machine._id)}
                >
                  <img
                    className="card-image"
                    src={machine.image ?? ClawMachineDefault}
                    alt={machine.machineName}
                  />
                  <div className="card-content">
                    <h3 className="card-title">{machine.machineName}</h3>
                    <p className="card-description">{machine.description}</p>
                    <p className="card-price">{machine.price ?? 10} coin/play</p>
                  </div>
                </div>
            )}
            </div>
          </div>
      ) : (
        <h2>Loading...</h2>
      )}
      <div style={{marginTop:"10px"}}>
        <div className="container-machine">
          <h4 style={{margin:"0px"}}>จำนวนสินค้ารอส่ง</h4>
          <ClaimPrizeSummary/>
        </div>
      </div>
      <div style={{marginTop:"10px"}}>
        <div className="container-machine">
          <h4 style={{margin:"0px"}}>stock</h4>
          <StockSummary/>
        </div>
      </div>
    </div>
      
  );
};

export default VendorPage;

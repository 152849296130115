import React, { useEffect, useState } from "react";
import axiosClient from "../../../util/AxiosClient";

export default function ClaimPrizeSummary() {
  const [claimItemSummary, setClaimItemSummary] = useState([]);

  useEffect(() => {
    fecthPendingShipping();
  }, []);

  const fecthPendingShipping = async () => {
    try {
      const response = await axiosClient.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/dashboard/pending-shipping`,

        {
          withCredentials: true,
        }
      );
      const claimItems = Object.entries(response.data.data).map(([key, value]) => ({
        name: key,
        quantity: value
      })).sort((a, b) => b.quantity - a.quantity);
      setClaimItemSummary(claimItems);
      return response.data;
    } catch (error) {
      console.log(error);
      // Handle login failure
    }
  };


  return (
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-bordered mb-0 text-center">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Qty</th>
                  </tr>
                </thead>
                <tbody>
                  {claimItemSummary.length > 0 &&
                    claimItemSummary.map((row, key) => (
                      <tr key={row.name}>
                        <td>{row.name}</td>
                        <td>{row.quantity}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

  );
}

import axios from "axios";

class AxiosClient {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL, // Base URL for API
      withCredentials: true, // Include cookies by default
    });

    // Add response interceptor
    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        return Promise.reject(error);
      }
    );
  }

  // Set Authorization token in headers
  setAuthToken() {
    const token = localStorage.getItem("token")
    if (token) {
      this.axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
    } else {
      delete this.axiosInstance.defaults.headers.common["Authorization"];
    }
  }

  // Wrapper for Axios GET request
  async get(url, config = {}) {
    this.setAuthToken()
    return this.axiosInstance.get(url, config);
  }

  // Wrapper for Axios POST request
  async post(url, data, config = {}) {
    this.setAuthToken()
    return this.axiosInstance.post(url, data, config);
  }

  // Wrapper for Axios PUT request
  async put(url, data, config = {}) {
    this.setAuthToken()
    return this.axiosInstance.put(url, data, config);
  }

  // Wrapper for Axios DELETE request
  async delete(url, config = {}) {
    this.setAuthToken()
    return this.axiosInstance.delete(url, config);
  }
}

const axiosClient = new AxiosClient();
export default axiosClient;

import React, { useState, useEffect } from "react";
import "./claimPage.css";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import Swal from "sweetalert2";
import axiosClient from "../util/AxiosClient";

const ClaimPage = () => {
  const navigate = useNavigate();
  const [telError, setTelError] = useState(false);
  const [info, setInfo] = useState({
    shippingName: "",
    email: "",
    tel: "",
    address: "",
  });
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const handleOptionChange = (option) => {
    setSelectedOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(option)) {
        return prevSelectedOptions.filter((item) => item !== option);
      } else {
        return [...prevSelectedOptions, option];
      }
    });
  };

  const [user, setUser] = useState({
    userName: "",
    email: "",
    tel: "",
    prize: [],
  });
  useEffect(() => {
    getMe().then((res) => {
      if (res) {
        setUser(res?.data);
      }
    });
  }, []);

  const getMe = async () => {
    try {
      const response = await axiosClient.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/me`,

        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      localStorage.clear();
      window.location.reload()
      // Handle login failure
    }
  };

  const validateTel = () => {
    const { tel } = info;
    if (!/^(\+\d{1,3})?\d{10}$/.test(tel)) {
      setTelError(true);
    } else {
      setTelError(false);
    }
  };

  const validateItem = () => {
    if (selectedOptions.length == 0) {
      setError("Please select your prize");
    } else {
      setError("");
    }
  };

  useEffect(() => {
    validateItem();
  }, [selectedOptions]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    setInfo((prevInfo) => {
        const updatedInfo = { ...prevInfo, [name]: value };
        return updatedInfo;
    });
};

  const handleClaim = async (e) => {
    e.preventDefault();
    if (isLoading) {
      return
    }
    setIsLoading(true)
    const payload = {
      ...info,
      address: `${info.address} ${info.province || ''} ${info['zip-code'] || ''}`,
      items: selectedOptions.map((item) => user.prize[item]),
    };
    await axiosClient
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/payment/claim`, payload)
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: `บันทึกข้อมูลเรียบร้อย ใช้เวลาประมาณ 7-14 ในการจัดส่งสินค้า\n เลขติดตามพัสดุจะส่งให้ทางอีเมล`,
        });
        navigate(`/`);
      })
      .catch(({ response }) => {
        Swal.fire({
          text: response.data.message,
          icon: "error",
        });
      }).finally(()=>{
        setIsLoading(false)
      })
  };

  return (
    <>
      <div className="my-container">
        <div className="my-card">
          <h2>ข้อมูลการจัดส่ง</h2>

          <Modal
            className="modal"
            isOpen={isModalOpen}
            onAfterOpen={() => setIsModalOpen(true)}
            onRequestClose={() => setIsModalOpen(false)}
            // style={customStyles}
            contentLabel="Example Modal"
          >
            <h3>สินค้าที่เลือกส่ง</h3>
            <div className="options-container">
              {user && user.prize?.length === 0 ? (
                <p>ไม่มีสินค้า</p>
              ) : (
                user.prize.map((option, i) => (
                  <div className="options-panel">
                    <label key={i}>
                      <input
                        type="checkbox"
                        value={option}
                        checked={selectedOptions.includes(i)}
                        onChange={() => handleOptionChange(i)}
                      />
                      {option.split(" ").slice(0, -1).join(" ") +
                        " (" +
                        new Date(Number(option.split(" ").slice(-1))).getHours() +
                        ":" +
                        new Date(
                          Number(option.split(" ").slice(-1))
                        ).getMinutes() +
                        ":" +
                        new Date(
                          Number(option.split(" ").slice(-1))
                        ).getSeconds() +
                        ", " +
                        new Date(
                          Number(option.split(" ").slice(-1))
                        ).toDateString() +
                        ")"}
                    </label>
                  </div>
                ))
              )}
            </div>
            

            <div className="btn-panel">
              <button
                type="button"
                className="close-btn"
                onClick={() => setIsModalOpen(false)}
              >
                ปิด
              </button>
            </div>
          </Modal>

          <Modal
            className="modal"
            isOpen={isModalConfirmOpen}
            onAfterOpen={() => setIsModalConfirmOpen(true)}
            onRequestClose={() => setIsModalConfirmOpen(false)}
            // style={customStyles}
            contentLabel="Example Modal"
          >
            <div style={{textAlign:"center"}}>
              <h2>สรุปข้อมูลการจัดส่ง</h2>
              <div className="options-container">
                <h3 style={{textAlign: "left"}}>ข้อมูลการจัดส่ง</h3>
                <p className="info">ชื่อผู้รับ: {info.shippingName}</p>
                <p className="info">อีเมล: {info.email}</p>
                <p className="info">เบอร์โทร: {info.tel}</p>
                <p className="info">ที่อยู่จัดส่ง: {info.address+" "+(info.province || '')+" "+(info['zip-code'] || '')}</p>
                <h3 style={{textAlign: "left"}}>สินค้าที่เลือกส่ง</h3>
                {selectedOptions.length >= 3 ? 
                  <p className="info" style={{color:"green"}}>จัดส่งฟรี</p> : 
                  <p className="warn">*หากสินค้าน้อยกว่า 3 ชิ้นจะมีค่าส่ง 50 Coins</p>
                }
                {selectedOptions.map((item) => (
                  <li className="info">
                    {user.prize[item].split(" ").slice(0, -1).join(" ") +
                      " (" +
                      new Date(
                        Number(user.prize[item].split(" ").slice(-1))
                      ).getHours() +
                      ":" +
                      new Date(
                        Number(user.prize[item].split(" ").slice(-1))
                      ).getMinutes() +
                      ":" +
                      new Date(
                        Number(user.prize[item].split(" ").slice(-1))
                      ).getSeconds() +
                      ", " +
                      new Date(
                        Number(user.prize[item].split(" ").slice(-1))
                      ).toDateString() +
                      ")"}
                  </li>
                ))}
              </div>
                
                <div className="btn-panel">
                  <button
                    type="button"
                    className="close-btn"
                    onClick={() => setIsModalConfirmOpen(false)}
                  >
                    ปิด
                  </button>
                  <button type="button" className="close-btn" onClick={handleClaim}>
                    ยืนยัน
                  </button>
                </div>
              
            </div>
            
          </Modal>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              setIsModalConfirmOpen(true);
            }}
          >
            <label style={{textAlign:"left", margin:"0"}}>ชื่อผู้รับ</label>
            <input
              type="text"
              name="shippingName"
              placeholder="Shipping name (Full name)"
              required
              onChange={handleInputChange}
            />
            <label style={{textAlign:"left", margin:"0"}}>อีเมล</label>
            <input
              type="email"
              name="email"
              placeholder="Email"
              required
              onChange={handleInputChange}
            />
            <label style={{textAlign:"left", margin:"0"}}>เบอร์โทร</label>
            <input
              type="tel"
              name="tel"
              placeholder="Telephone"
              required
              onChange={handleInputChange}
              onBlur={validateTel}
            />
            {telError && <p className="error">เบอร์โทรไม่ถูกต้อง</p>}
            <label style={{textAlign:"left", margin:"0"}}>ที่อยู่จัดส่ง</label>
            <input
              type="text"
              name="address"
              placeholder="Address"
              required
              onChange={handleInputChange}
            />
            <label style={{textAlign:"left", margin:"0"}}>จังหวัด</label>
            <input
              type="text"
              name="province"
              placeholder="Province"
              required
              onChange={handleInputChange}
            />
            <label style={{textAlign:"left", margin:"0"}}>รหัสไปรษณีย์</label>
            <input
              type="text"
              name="zip-code"
              placeholder="Zip-code"
              pattern="^[0-9]{5}$"
              inputMode="numeric"
              required
              onChange={handleInputChange}
            />

            <div className="multi-choice">
              <button
                style={{ marginBottom: "10px", userSelect: "none" }}
                type="button"
                className="toggle-btn"
                onClick={() => setIsModalOpen(true)}
              >
                เลือกสินค้า
              </button>
            </div>

            <button
              style={{ userSelect: "none" }}
              type="submit"
              disabled={telError || selectedOptions.length == 0}
            >
              ยืนยันจัดส่ง
            </button>
            {error && <p className="error">{error}</p>}
            {msg && <p className="msg">{msg}</p>}
          </form>
        </div>
      </div>
    </>
  );
};

export default ClaimPage;

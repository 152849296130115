import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axiosClient from "../../../util/AxiosClient";

export default function EditPrize({ id }) {
  const navigate = useNavigate();

  const [prize, setPrize] = useState({});
  const [machines, setMachines] = useState([]);
  const [validationError, setValidationError] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchPrize();
    fetchMachine();
  }, []);


  const fetchPrize = async () => {
    await axiosClient
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/prize/${id}`)
      .then(({ data }) => {
        setPrize(data.data);
      })
      .catch(({ response: { data } }) => {
        Swal.fire({
          text: data.message,
          icon: "error",
        });
      });
  };

  const fetchMachine = async () => {
    await axiosClient
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/machine/`)
      .then(({ data }) => {
        setMachines(data.data);
      })
      .catch(({ response: { data } }) => {
        Swal.fire({
          text: data.message,
          icon: "error",
        });
      });
  };

  const updatePrize = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    await axiosClient
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/prize/${id}`,
        prize,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file upload
          },
        }
      )
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.success,
        });
        navigate("/admin/prize");
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setValidationError(response.data.errors);
        } else {
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        }
      }).finally(()=>{
        setIsSubmitting(false)
      });;
  };

  return (
      <div className="col-12 col-sm-12 col-md-6">
        <div className="my-card">
          <div className="card-body">
            <h4 className="create-title">Update Prize</h4>
            <hr />
            <div className="form-wrapper">
              {Object.keys(validationError).length > 0 && (
                <div className="row">
                  <div className="col-12">
                    <div className="alert alert-danger">
                      <ul className="mb-0">
                        {Object.entries(validationError).map(([key, value]) => (
                          <li key={key}>{value}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              <Form onSubmit={updatePrize}>
                <Row>
                  <Col>
                    <Form.Group controlId="Name">
                      <Form.Label>Name</Form.Label>
                      <br/>
                      <Form.Control className="input_update"
                        type="text"
                        value={prize.name}
                        onChange={(event) => {
                          setPrize({...prize, name:event.target.value});
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="my-0">
                  <Col>
                    <Form.Group controlId="Description">
                      <Form.Label>Description</Form.Label>
                      <br/>
                      <Form.Control className="input_update"
                        as="textarea"
                        rows={3}
                        value={prize.description}
                        onChange={(event) => {
                          setPrize({...prize, description:event.target.value});

                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="Image" className="mb-0">
                      <Form.Label>Image</Form.Label>
                      <br/>
                      <div>
                        {selectedImage ? 
                        <img
                          alt="not found"
                          width={"200px"}
                          src={URL.createObjectURL(selectedImage)}
                        />
                        : <img src={prize.image} width="100px" />}
                        <br/>
                        <Form.Control
                        type="file"
                        name="prizeImage"
                        // Event handler to capture file selection and update the state
                        onChange={(event) => {
                          setPrize({...prize, image:event.target.files[0]}); // Update the state with the selected file
                          setSelectedImage(event.target.files[0]);
                        }}
                      />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="Price" className="mb-0">
                      <Form.Label>Price</Form.Label>
                      <br/>
                      <Form.Control className="input_update"
                        type="number"
                        min="0"
                        value={prize.price}
                        onChange={(event) => {
                          setPrize({...prize, price:event.target.value});

                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="GuaranteePrice" className="mb-2">
                      <Form.Label>GuaranteePrice</Form.Label>
                      <br/>
                      <Form.Control className="input_update"
                        type="number"
                        min="0"
                        value={prize.guaranteePrice}
                        onChange={(event) => {
                          setPrize({...prize, guaranteePrice:event.target.value});

                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="StockAmount" className="mb-2">
                      <Form.Label>StockAmount</Form.Label>
                      <br/>
                      <Form.Control className="input_update"
                        type="number"
                        min="0"
                        value={prize.stockAmount}
                        onChange={(event) => {
                          setPrize({...prize, stockAmount:event.target.value});

                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="machineSelectedId" className="mb-2">
                        <Form.Label>Machine Selected</Form.Label>
                        <br/>
                        <Form.Select className="input_update"
                          value={prize.machineSelectedId}
                          onChange={(event) => {
                            const selectedValue = event.target.value === "" ? null : event.target.value;
                            setPrize({ ...prize, machineSelectedId: selectedValue });
                          }}
                        >
                          <option value={""}>ยังไม่ได้เครื่อง</option>
                          {machines.map(machine=>{
                            return <option value={machine._id}>{machine.machineName}</option>
                          })}
                          
                          
                        </Form.Select>
                      </Form.Group>
                  </Col>
                </Row>
                <Button
                  variant="primary"
                  className="mt-2"
                  size="lg"
                  block="block"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Update
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
  );
}

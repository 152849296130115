import React, { useEffect, useState } from "react";
import "./DailyLogin.css";
import coinIcon from '../../resorce/coin-icon.png'

const DailyLoginBonus = ({ streak, isOpen, dailyBonus = [], setUser, setIsDailyLoinOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(()=>{
    let i = 0
    const intervalId = setInterval(() => {
      if (i <= streak) {
        const stamp = document.getElementById(`stamp-${i}`);
        if(stamp) stamp.classList.add("stamped");
        i++
      } else {
        clearInterval(intervalId);
      }
    }, 150);
  },[streak])


  if(!isOpen) {return null}

  const handleDailyLogin = () => {
    setIsLoading(true);
    const user = JSON.parse(localStorage.getItem("user"));
    const newUser = { ...user, coin: user.coin + dailyBonus[streak] };
    localStorage.setItem("user", JSON.stringify(newUser));

    // Slight delay to ensure the animation triggers smoothly
    setTimeout(() => {
      const stamp = document.getElementById(`stamp-${streak + 1}`);
      if (stamp) {
        stamp.classList.add("stamped");
      }
    }, 100);

    setTimeout(() => {
      let currentCoin = user.coin;
      const newCoin = newUser.coin;
      const intervalId = setInterval(() => {
        if (currentCoin < newCoin) {
          currentCoin++;
          setUser({ ...newUser, coin: currentCoin });
        } else {
          clearInterval(intervalId);
        }
      }, 2000/(newUser.coin-user.coin));

      setIsDailyLoinOpen(false);
    }, 500);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h1 className="bonus-title">🎊โบนัส ล็อกอินต่อเนื่อง🎉</h1>
        <div className="bonus-calendar">
          <div className="bonus-row">
            {/* First row for days 1-4 */}
            {dailyBonus.slice(0, 4).map((bonus, index) => (
              <div
                key={`day-${index + 1}`}
                className={`bonus-day ${streak + 1 === index + 1 ? "active" : ""} ${streak === index + 1 ? "claimed" : ""}`}
              >
                <div style={{color:"gray"}}>Day {index + 1}</div>
                <div>
                  {bonus} 
                  <img src={coinIcon} alt="" style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: "5px",
                  verticalAlign: "middle"}} />
                </div>
                {(streak >= index + 1 || streak + 1 === index + 1) && <div id={`stamp-${index + 1}`} className="stamp">Claimed</div>}
              </div>
            ))}
          </div>
          <div className="bonus-row">
            {/* Second row for days 5-7 */}
            {dailyBonus.slice(4).map((bonus, index) => (
              <div
                key={`day-${index + 5}`}
                className={`bonus-day ${streak + 1 === index + 5 ? "active" : ""} ${streak === index + 5 ? "claimed" : ""}`}
              >
                <div style={{color:"gray"}}>Day {index + 5}</div>
                <div>
                  {bonus} 
                  <img src={coinIcon} alt="" style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: "5px",
                  verticalAlign: "middle"}} />
                </div>
                {(streak >= index + 5 || streak + 1 === index + 5) && <div id={`stamp-${index + 5}`} className="stamp">Claimed</div>}
              </div>
            ))}
          </div>
        </div>
        <button
          className="claim-button"
          disabled={isLoading}
          onClick={handleDailyLogin}
        >
          รับรางวัลล็อกอินรายวัน
        </button>
      </div>
    </div>
  );
};

export default DailyLoginBonus;

import React, { useEffect, useState } from "react";
import axiosClient from "../util/AxiosClient";
import { useParams } from "react-router-dom";
import PlayingClawPage from "./playingClawPage";
import PlayingClipPage from "./playingClipPage";

const PlayingPage = ({setIsTopUpModalOpen, setUser}) => {
    const [prizeData, setPrizeData] = useState({
    _id: "",
    isOnline: false,
    image: "",
    prizeName: "Not Found",
    price: 99999,
    guaranteePrice: 99999,
    description: "",
    machineSelectedId: null,
    type: "CLAW"
    });
    const { prizeId } = useParams();
    useEffect(() => {
        getPrize().then((data) => {
          if (data) {
            setPrizeData(data);
          }
        });
      }, []);

    const getPrize = async () => {
        try {
            const response = await axiosClient.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/prize/${prizeId}/user`,

            {
                withCredentials: true,
            }
            );
            return response.data.data;
        } catch (error) {
            console.log(error);
            // Handle login failure
        }
    };

  let componentToRender = {
    "CLAW":<PlayingClawPage setIsTopUpModalOpen={setIsTopUpModalOpen} setUser={setUser}/>,
    "CLIP":<PlayingClipPage setIsTopUpModalOpen={setIsTopUpModalOpen} setUser={setUser}/>
  };

  return (
    <>
      {prizeData._id !== ""?
        componentToRender[prizeData.type]
      :null}
    </>
  );
};
export default PlayingPage;

import React, { useState, useEffect } from "react";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import Countdown from "react-countdown";
import promptpay_logo from "../resorce/promptpay.png";
import "./paymentPage.css";
import axiosClient from "../util/AxiosClient";

const PaymentPage = () => {
  const { amount } = useParams();
  const [QR, setQR] = useState();
  useEffect(() => {
    if (["50", "100", "300", "500", "1000"].includes(amount)) {
      getQR().then((res) => {
        if (res) {
          setQR(res?.data);
        }
      });
    }
  }, []);
  const getQR = async () => {
    try {
      const response = await axiosClient.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/payment/`,
        {
          amount: Number(amount),
        },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  if (!["50", "100", "300", "500", "1000"].includes(amount)) {
    return <h1>Amount Invalid</h1>;
  }
  const Completionist = () => (
    <span>
      <button style={{marginBottom: "50px"}} onClick={()=>window.location.reload()}>โหลด QRCode ใหม่</button>
      <br />
      QR Code expired
      <br />
      หากชำระเงินแล้วกรุณารอสักครู่ หรือติดต่อ Line: KEEB
    </span>
  );
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <>
          <h3 className="payment"> Please scan before:</h3>
          <h3 className="payment">
            {minutes}:{seconds}
          </h3>
          <img className="payment-img" src={promptpay_logo}></img>

          <QRCode className="payment" value={QR?.QRcode ? QR?.QRcode : ""} />
          <h3 className="payment">ราคา {amount} บาท</h3>
          <p style={{ color: "red" }} className="payment">*ยังไม่รวมค่าดำเนินการไม่เกิน 1 บาท</p>

        </>
      );
    }
  };
  const dateObject = new Date(QR?.createdAt);
  const timeRemaining = dateObject.getTime();
  return (
    <div className="payment-page">
      <div className="container-payment">
        {timeRemaining ? ( // Only render Countdown if timeRemaining is not 0 (or NaN)
          <Countdown date={timeRemaining + 5 * 60 * 1000} renderer={renderer} />
        ) : (
          <h2>Loading</h2>
        )}
        <h3 style={{ color: "red" }}>
          เมื่อชำระเงินเสร็จแล้วกรุณารอเงินเข้าประมาณ 3 นาที
        </h3>
      </div>
    </div>
  );
};

export default PaymentPage;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import "./crud.css";
import Form from 'react-bootstrap/Form';
import axiosClient from "../../../util/AxiosClient";

export default function ListPrize() {
  const [prizes, setPrizes] = useState([]);

  useEffect(() => {
    fetchPrizes();
  }, []);

  const fetchPrizes = async () => {
    try {
      const response = await axiosClient.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/prize/`,

        {
          withCredentials: true,
        }
      );
      const res = response.data.data
      setPrizes(res);
      return res
    } catch (error) {
      console.log(error);
      // Handle login failure
    }
  };

  const deletePrize = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }
    await axiosClient
      .delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/prize/${id}`,

        {
          withCredentials: true,
        }
      )
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
        fetchPrizes();
      })
      .catch(({ response: { data } }) => {
        Swal.fire({
          text: data.message,
          icon: "error",
        });
      });
  };

  const toggleEnablePrize = async (id) => {
    const prize = prizes.find(prize => prize._id === id)
    const prizeIsEnableStatus = prize.isEnable || false
    const isConfirm = await Swal.fire({
      title: "Are you sure to togle enable status?",
      text: `set to ${prizeIsEnableStatus ? "disable" :"enable"}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, toggle!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    await axiosClient
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/prize/${id}`,
        {
          isEnable: !prizeIsEnableStatus
        }
      )
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
        fetchPrizes();
      })
      .catch(({ response: { data } }) => {
        Swal.fire({
          text: data.message,
          icon: "error",
        });
      });
  };

  return (

      <div className="crud-container">
        <div className="row">
          <div>
            <Link className="btn btn-primary mb-2 float-end" to={"prize/create"}>
              Create Prize
            </Link>
          </div>
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-bordered mb-0 text-center">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Price</th>
                    <th>Guarantee</th>
                    <th>machineSelected</th>
                    <th>stockAmount</th>
                    <th>Actions</th>
                    <th>Enable to show</th>
                  </tr>
                </thead>
                <tbody>
                  {prizes.length > 0 &&
                    prizes.map((row, key) => (
                      <tr key={row._id}>
                        <td>
                          <img width="100px" src={row.image} />
                        </td>
                        <td>{row.name}</td>
                        <td>{row.description || '-'}</td>
                        <td>{row.price}</td>
                        <td>{row.guaranteePrice}</td>
                        <td>{row.machineName ?? "-"}</td>
                        <td>{row.stockAmount || 0}</td>
                        <td>
                          <Link
                            to={`prize/edit/${row._id}`}
                            className="btn btn-success"
                          >
                            Edit
                          </Link>
                          <Button
                            className="btn"
                            variant="danger"
                            onClick={() => deletePrize(row._id)}
                          >
                            Delete
                          </Button>
                        </td>
                        <td>
                          <Form>
                            <Form.Check // prettier-ignore
                              type="switch"
                              checked = {row.isEnable ||false}
                              onClick={()=>{
                                toggleEnablePrize(row._id)
                              }}
                            />
                          </Form>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  );
}

import React, { useState } from "react";
import "./couponPage.css";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import exampleImg from "../resorce/email-verify-example.png"
import axiosClient from "../util/AxiosClient";

const CouponPage = () => {
  const navigate = useNavigate();
  const [couponCode, setCouponCode] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const user = JSON.parse(localStorage.getItem("user"))

  const handleSubmitCoupon = async (e) => {
    e.preventDefault();
    if (isSubmitting){
      return
    }
    setIsSubmitting(true)
    const payload = {
      code:couponCode
    };
    await axiosClient
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/coupon/submit`, payload)
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.success,
        });
        navigate(`/`);
      })
      .catch(({ response }) => {
        Swal.fire({
          text: response.data.message,
          icon: "error",
        });
      })
      .finally(()=>{
        setIsSubmitting(false)
      })
  };

  const handleVerifyEmail= async (e) => {
    e.preventDefault();
    if (isSubmitting){
      return
    }
    const isConfirm = await Swal.fire({
      title: `วิธียืนยันอีเมล`,
      text: `เมื่อกดส่งอีเมลแล้ว ให้เข้าอีเมลเพื่อกดปุ่มยืนยันอีเมลจากอีเมลที่ KEEB ส่งให้`,
      imageUrl: exampleImg,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ส่งอีเมล",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }
    const payload = {
      code:couponCode
    };
    setIsSubmitting(true)
    await axiosClient
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/verify`, payload)
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
      })
      .catch(({ response }) => {
        Swal.fire({
          text: response.data.message,
          icon: "error",
        });
      })
      .finally(()=>{
        setIsSubmitting(false)
      })
  };

  return (
    <>
      <div className="my-container" style={{height:"70vh"}}>
        <div className="my-card">
          <h2>โค้ดคูปอง🎉</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <label style={{textAlign:"left", margin:"0"}}>รหัสคูปอง</label>
            <input
              type="text"
              name="couponCode"
              placeholder="กรอกคูปอง"
              required
              onChange={(e)=>setCouponCode(e.target.value)}
            />
            <button
              className="code-submit"
              style={{ userSelect: "none" }}
              type="submit"
              disabled={couponCode.length === 0 || !user.verified}
              onClick={handleSubmitCoupon}
            >
              {user.verified ? "ยืนยัน" : "กรุณายืนยันอีเมลก่อน"}
            </button>
            {
              !user.verified?
              <button
                className="code-submit"
                style={{ userSelect: "none", marginTop:"5px" }}
                type="submit"
                disabled={user.verified}
                onClick={handleVerifyEmail}
              >
                ยืนยันอีเมล
              </button>
              : null
            }
          </form>
        </div>
      </div>
    </>
  );
};

export default CouponPage;

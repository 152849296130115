import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import success from "../../resorce/success.png";
import styles from "./emailVerify.css";
import { useNavigate } from "react-router-dom";
import axiosClient from "../../util/AxiosClient";
// import { Fragment } from "react/cjs/react.production.min";

const EmailVerify = () => {
  const [validUrl, setValidUrl] = useState(false);
  const param = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const verifyEmailUrl = async () => {
      try {
        const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/${param.id}/verify/${param.token}`;
        const { data } = await axiosClient.get(url);
        console.log(data);
        setValidUrl(true);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } catch (error) {
        console.log(error);
        setValidUrl(false);
      }
    };
    console.log("i fire once");
    verifyEmailUrl();
  }, []);

  return (
    <div className="container-machine">
      {validUrl ? (
        <div className={styles.container}>
          <img src={success} alt="success_img" className={styles.success_img} />
          <h3>ยืนยันอีเมล สำเร็จ</h3>
          <Link to="/" className="nav-link">
            กลับหน้าหลัก
          </Link>
        </div>
      ) : (
        <h3>link ยืนยันไม่ถูกต้องกรุณายืนยันด้วย link ที่ส่งล่าสุด</h3>
      )}
    </div>
  );
};

export default EmailVerify;

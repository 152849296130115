import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";



const Alarm = () => {
    const [ws, setWs] = useState(null);
    const userId = JSON.parse(localStorage.getItem("user"))?._id;
    const [alarms, setAlarms] = useState([])

    useEffect(() => {
        // WebSocket URL
        // const WS_URL = "wss://keeb-tgo4qagcpa-as.a.run.app";
        const WS_URL = process.env.REACT_APP_WSS_URI;
        // const WS_URL = "ws://localhost:5000";
        // Create the WebSocket connection when the component mounts
        const newWs = new WebSocket(WS_URL);
        newWs.onclose = () => {
          newWs.send(`CLOSE_CLIENT_ALARM ${userId}`);
        };
    
        newWs.onopen = () => {
          console.log(`Connected to ${newWs.url}`);
          newWs.send(
            `INIT_CLIENT_ALARM ${userId}`
          );
        };
        setWs(newWs);
    
        // Clean up the WebSocket connection when the component unmounts
        return () => {
          if (newWs.readyState === WebSocket.OPEN) {
            newWs.send(`CLOSE_CLIENT_ALARM ${userId}`);
            newWs.close();
          }
        };
      },[]);

      useEffect(() => {
        // Function to handle incoming messages from the WebSocket
        const handleWsMessage = async (message) => {
          // console.log(message.data);
          const jsonMessage = JSON.parse(message.data); // Parse the incoming JSON message
          console.log(jsonMessage.alarm)
          setAlarms(jsonMessage.alarm)
        }
        // Attach the WebSocket message handler when the WebSocket is available
        if (ws) {
          ws.onmessage = handleWsMessage;
    
          ws.onclose = () => {
            ws.send(`CLOSE_CLIENT_ALARM ${userId}`);
          };
        }
      }, [ws]);

      const handleActionAlarm = (alarm)=>{
        if(alarm.isAlarm){
            ws.send(`SEND_STOP_ALARM ${alarm.id}`);
        }else{
            ws.send(`SEND_START_ALARM ${alarm.id}`);
        }

      }
  return (
    <div className="container-machine">
        <h2>แจ้งเตือน</h2>
        <div className="col-12">
            <div className="table-responsive">
              <table className="table table-bordered mb-0 text-center">
                <thead>
                  <tr>
                    <th>id</th>
                    <th>status</th>
                    <th>action</th>
                  </tr>
                </thead>
                <tbody>
                  {alarms.length > 0 &&
                    alarms.map((row, key) => (
                      <tr key={row._id}>
                        
                        <td>{row.id}</td>
                        <td style={{color:row.isAlarm?"red":"green"}}>{row.isAlarm?"Alert":"Not Alert"}</td>
                    
                        <td>
                          <Button
                            className="btn"
                            variant="danger"
                            onClick={() => handleActionAlarm(row)}
                          >
                            {row.isAlarm?"หยุดส่งเสียง":"ส่งเสียง"}
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
    </div>
  );
};
export default Alarm;
